angular.module('wp-common-filters', [])
    .filter('range', [function () {
        return function (input, total) {
            total = parseInt(total);
            for (var i = 0; i < total; i++)
                input.push(i);
            return input;
        };
    }])

    .filter('hardrange', [function () {
        return function (input) {
            var lowBound, highBound;
            switch (input.length) {
                case 1:
                    lowBound = 0;
                    highBound = parseInt(input[0]) - 1;
                    break;
                case 2:
                    lowBound = parseInt(input[0]);
                    highBound = parseInt(input[1]);
                    break;
                default:
                    return input;
            }
            var result = [];
            for (var i = lowBound; i <= highBound; i++)
                result.push(i);
            return result;
        };
    }])

    .filter('paginationOffset', [function () {
        return function (input, page, limit) {
            var begin = (page - 1) * limit;
            return input ? input.slice(begin, begin + limit) : input;
        };
    }])

    .filter('contentFilter', ['$filter', function ($filter) {
        return function (input, fOptions, fFields) {
            var fObj = {
                contentData: input,
                filtered: [],
                collection: [],
                applyFilter: function (option, key) {
                    var fData = [];
                    if (this.filtered.length == 0) this.filtered = this.contentData;
                    if (!option || option == "") fData = this.filtered;
                    if (option && option.length) {
                        var fField = _.findWhere(fFields, {id: key});
                        switch (key) {
                            case 'month':
                                fData = fData.concat(_.filter(this.filtered, function (obj) { return $filter('date')(Date.parse(obj[fField.attr]), "M") ==  option; }));
                                break;
                            case 'year':
                                fData = fData.concat(_.filter(this.filtered, function (obj) { return $filter('date')(Date.parse(obj[fField.attr]), "yyyy") ==  option; }));
                                break;
                            default:
                                fData = fData.concat(_.filter(this.filtered, function (obj) { return obj[fField.attr] == option;}));
                                break;
                        }
                    }
                    this.collection.push(fData);
                },
                hasEmptyData: function () {
                    var emptyData = _.filter(this.collection, function (obj) {
                        return _.isEmpty(obj);
                    });
                    return emptyData.length > 0;
                }
            };

            if (!_.some(fOptions)) return input;

            _.each(fOptions, function (option, key) {
                fObj.applyFilter(option, key);
            });

            if (fObj.hasEmptyData()) fObj.filtered = [];
            else fObj.filtered = _.intersection.apply(_, fObj.collection);

            return fObj.filtered;
        };
    }]);
