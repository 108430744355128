angular.module('wp-front-services', [])

    .factory('Auth', ['WpRequest', '$cookieStore', function (WpRequest, $cookieStore) {
        var Auth = {},
            isSigned = null;

        var signout = function () {
            isSigned = null;
            $cookieStore.remove('auth:was_here');
            Auth.credentials = {
                id: '',
                signed: false,
                screen_name: '',
                thumb_header: null,
                thumb_preview: null
            };
        };

        Auth.credentials = {
            id: '',
            signed: false,
            screen_name: '',
            upload_slug: null
        };

        Auth.signed = function () {
            return isSigned;
        };

        Auth.setWasHere = function () {
            $cookieStore.put('auth:was_here', true);
        };

        Auth.wasHere = function () {
            return $cookieStore.get('auth:was_here') ? $cookieStore.get('auth:was_here') : false;
        };

        Auth.authenticate = function (credentials) {
            return WpRequest._request('POST', "/request/auth/authenticate", {
                User: {
                    login: credentials.user_login,
                    password: credentials.user_password
                }
            });
        };

        Auth.signin = function (credentials, is_social) {
            return WpRequest._request('POST', "/request/auth/signin", {
                User: {
                    login: credentials.user_login,
                    password: credentials.user_password,
                    is_social: is_social
                }
            });
        };

        Auth.signout = function () {
            signout();
            return WpRequest._request('POST', "/request/auth/signout");
        };

        Auth.getUserInfo = function () {
            return WpRequest._request('GET', "/request/auth/getUserInfo", {
                getInfo: true
            });
        };

        Auth.retrieveUserInfo = function () {
            return WpRequest._request('GET', "/request/auth/retrieveUserInfo", {
                retrieveInfo: true
            });
        };

        return Auth;
    }])

    .factory('Sms', ['WpRequest', function (WpRequest) {
        var Sms = {
            actions: {signup: 0, signin: 1, update: 2, payment: 3}
        };

        var _pid = null,
            _opts = {
                phone: null,
                fetch_attempt: null,
                action_data: { id: null, attrs: { /** can contain only 'name', 'param' & 'amount' attributes **/}}
            };

        Sms.getPid = function () {
            return _pid;
        };

        Sms.setOptions = function (pid, phone, action_id, action_attrs, fetch_attempt) {
            _pid = pid;
            _opts.phone = phone;
            _opts.action_data.id = parseInt(action_id, 10);
            _opts.action_data.attrs = angular.copy(action_attrs);
            _opts.fetch_attempt = fetch_attempt;
        };

        Sms.send = function(resend) {
            delete _opts.instance;
            _opts.resend = resend;
            return WpRequest._request("POST", "/request/sms/sendVerificationCode", _opts);
        };

        Sms.verify = function (instance) {
            _opts.instance = instance;
            return WpRequest._request("POST", "/request/sms/verifyCode", _opts);
        };
        
        Sms.fakeTry = function() {
            delete _opts.instance;
            return WpRequest._request("POST", "/request/sms/fakeTry", _opts);
        };
        
        return Sms;
    }])

    .factory('DateSelector', [function () {
        var DateSelector = {
            days: [],
            months: [],
            years: []
        };

        DateSelector.populate = function () {
            DateSelector.days = [
                //{value: '...'}
            ];
            DateSelector.months = [
                //{value: '...'}
            ];
            DateSelector.years = [
                //{value: '...'}
            ];

            for (var i = 1; i <= 31; i++)
                DateSelector.days.push({value: i});
            for (var m = 1; m <= 12; m++)
                DateSelector.months.push({value: m});

            var current_day = new Date();
            var current_year = current_day.getFullYear() - 18;
            for (var y = 0; y < 100; y++) {
                DateSelector.years.push({value: current_year});
                current_year -= 1;
            }
        };

        DateSelector.refresh = function (month, year) {
            var date = new Date(year, month, 0);
            var lastDay = date.getDate();
            if (lastDay) {
                DateSelector.days = [
                    {value: '...'}
                ];
                for (var i = 1; i <= lastDay; i++)
                    DateSelector.days.push({value: i});
            }
        };

        return DateSelector;
    }])

    .factory('Social', ['$cookieStore', 'WpRequest', function ($cookieStore, WpRequest) {
        var Social = {};
        var Data = {
            action: null,
            access: null,
            error: null,
            status: null,
            user: {
                user_profile_attributes: {},
                user_social_attributes: {}
            }
        };

        Social.get = function () {
            var store = $cookieStore.get('social_auth') ? $cookieStore.get('social_auth') : null;
            if (store) {
                Data.status = true;
                Data.action = store.action ? parseInt(store.action) : null;
                Data.access = store.access ? 1 : 0;
                parseProfile(store.data);
            } else {
                Data.status = null;
            }
            unset();

            return Data;
        };

        Social.setSignup = function (provider) {
            unset();
            $cookieStore.put('provider:signup', provider);
        };

        Social.setSignin = function (provider) {
            unset();
            $cookieStore.put('provider:signin', provider);
        };

        Social.setBind = function (provider) {
            unset();
            $cookieStore.put('provider:bind', provider);
        };

        var unset = function () {
            $cookieStore.remove('social_auth');
            $cookieStore.remove('provider:signup');
            $cookieStore.remove('provider:signin');

            return WpRequest._request("POST", "/request/auth/unsetsocial", { unset: true });
        };

        var parseProfile = function (data) {
            if (data) {
                Data.user = {
                    user_surname: data.user_surname ? data.user_surname : null,
                    user_name: data.user_name ? data.user_name : null,
                    user_patronymic: null,
                    user_new_password: null,
                    user_compare_password: null,
                    user_email: data.user_email ? data.user_email : null
                };
                Data.user.user_profile_attributes = {
                    user_phone: data.user_phone ? data.user_phone : null,
                    user_birthdate: data.user_birthdate ? data.user_birthdate : null
                };
                Data.user.user_social_attributes = {
                    social_fb_id: data.social_fb_id ? data.social_fb_id : null,
                    social_vk_id: data.social_vk_id ? data.social_vk_id : null,
                    social_tw_id: data.social_tw_id ? data.social_tw_id : null,
                    social_gp_id: data.social_gp_id ? data.social_gp_id : null,
                    social_mail_id: data.social_mail_id ? data.social_mail_id : null,
                    social_ok_id: data.social_ok_id ? data.social_ok_id : null,
                    upload_file: data.upload_file ? data.upload_file : null
                };
                Data.status = true;
            } else {
                parseErrors();
            }
        };

        var parseErrors = function (data) {
            if (data) {
                Data.error = data.error;
            } else {
                Data.error = 'Unknown error';
            }
        };

        return Social;
    }])

    .factory('Services', ['WpRequest', function (WpRequest) {
        var Services = {};

        Services.getMatches = function (keyword) {
            keyword = keyword ? keyword.toLowerCase() : '';
            return WpRequest._request("POST", "/request/services/getMatches", {
                keyword: keyword
            });
        };

        return Services;
    }])

    .factory('PaymentData', [function () {
        var PaymentData = {},
            __data = {
                id: null,
                service_id: null,
                param: null,
                amount: null,
                params: {},
                step: 'Fields'
            },
            _data = $.extend({}, __data);

        PaymentData.hasPayment = function () {
            return _data.id !== null;
        };

        /**
         * setter
         * @param p - property
         * @param v - value
         */
        PaymentData.set = function (p, v) {
            _data[p] = v;
        };

        /**
         * getter & reset
         * @param p - property
         * @param r - reset (true)
         */
        PaymentData.get = function (p, r) {
            var value = _data[p];
            if (r === true) {
                this.set(p, null);
            }
            return value;
        };

        /**
         * reset
         */
        PaymentData.reset = function () {
            _data = $.extend(__data);
        };


        PaymentData.setPaymentId = function (paymentId) {
            _data.id = paymentId;
        };

        PaymentData.setServiceId = function (serviceId) {
            _data.service_id = serviceId;
        };

        PaymentData.setParam = function (param) {
            _data.param = param;
        };


        PaymentData.setAmount = function (paymentAmount) {
            _data.amount = paymentAmount;
        };


        return PaymentData;
    }])

    .factory('DemoData', ['$http', '$q','$rootScope', function ($http, $q, $rootScope) {
        var Obj = {
                parts: ['personal', 'payments', 'templates', 'services']
            },
            _master = {},
            _storage = {};

        Obj.init = function () {
            var deferred = $q.defer();

            $http({method: 'GET', url: $rootScope.wpPublishDir  + 'widget/demo/assets/json/demo_data.json', responseType: 'json'})
                .success(function (data) {
                    _master = data;
                    _storage = angular.copy(_master);
                    deferred.resolve(_master);
                }).error(function () {
                    deferred.reject();
                });

            return deferred.promise;
        };

        Obj.getData = function (part) {
            if (_storage) {
                return part ? _storage[part] : _storage;
            } else {
                return part ? _master[part] : _master;
            }
        };

        Obj.storeData = function (part, data) {
            _storage[part] = angular.copy(data);
        };

        Obj.storeUserData = function (part, data) {
            _storage.user[part] = angular.copy(data);
        };

        Obj.getUserAttempt = function () {
            var attempt = ('000' + _storage.user.sms_attempt).slice(-3);
            _storage.user.sms_attempt++;
            return attempt;
        };

        return Obj;
    }])

    .factory('contentFeedHelper', ['controllerResources', 'contentFeedEntitiesService', function (controllerResources, contentFeedEntitiesService) {
        var key = "contentFeed";
        return {
            helper: contentFeedHelper
        };
        function contentFeedHelper($scope) {
            var resources = controllerResources[key];
            preInit();

            function parseSettings() {
                if (!resources.settingsFields) return;
                _.each(resources.settingsFields, function (key, field) {
                    $scope[field] = $scope.settings && $scope.settings[key];
                });
            };

            function preInit() {
                if (!resources) return;
                parseSettings();
                $scope.contextLoaded = false;

                if (_.contains(resources.modes[$scope.type], "context")) {
                    contentFeedEntitiesService.get($scope.type, {mode: 'context'}, function (response) {
                        $scope.filterFields = (response && response.filterFields) || [];
                        $scope.filterOptions = {};
                        _.each($scope.filterFields, function (attrs) {
                            if (attrs) {
                                var value = (resources.defaultFilters[$scope.type] && resources.defaultFilters[$scope.type][attrs.id]) && $scope[resources.defaultFilters[$scope.type][attrs.id]];
                                $scope.filterOptions[attrs.id] = value || "";
                            }
                        });
                        $scope.contextLoaded = true;
                    });
                } else {
                    $scope.contextLoaded = true;
                }

                $scope.$watch('contextLoaded', function (value) {
                    if (value) init();
                });
            }

            function init() {
                $scope.params = {};
                $scope.dataFields = resources && resources.dataFields[$scope.type];
                $scope.contentData = null;
                $scope.paginationOptions = {
                    currentPage: (resources && resources.defaults.page) || 1,
                    itemsPerPage: ($scope.onpage || (resources && resources.defaults.onpage)) || 10,
                    maxSize: 5,
                    totalItems: 1,
                    countRange: [{value:5,name:5},{value:10,name:10},{value:20,name:20},{value:50,name:50}]
                };

                $scope.parseDate = function (str) {
                    return Date.parse(str);
                };

                run();
            };

            function run() {
                var params = ["page", "onpage", "size", "filter", "mode"],
                    defaults = resources && resources.defaults;
                    defaults.mode = (resources && resources.defaultModes[$scope.type]) || "default";
                _.each(params, function (paramName) {
                    if (_.has(defaults, paramName)) {
                        var param = {};
                        param[paramName] = defaults[paramName];
                        _.extend($scope.params, param);
                    }
                });

                $scope.$watchCollection('filterOptions', function (options) {
                    if (!_.isEmpty(options))
                        $scope.params['filter'] = angular.toJson(options);
                });
                $scope.$watchCollection('paginationOptions', function (options) {
                    $scope.params['page'] = options['currentPage'] ? options['currentPage'] : 1;
                    $scope.params['onpage'] = options['itemsPerPage'] ? options['itemsPerPage'] : 10;
                });

                $scope.$watchCollection('params', function (params) {
                    load(params);
                });
            };

            function load(params) {
                contentFeedEntitiesService.get($scope.type, params, function (response) {
                    if (!response) return;
                    $scope.contentData = response.items;
                    $scope.caption = response.caption || "";
                    $scope.noResultCaption = response.noResultCaption || "";
                    $scope.paginationOptions.totalItems = parseInt(response.total, 10);
                }, function () {
                    $scope.paginationOptions.currentPage = (resources && resources.defaults.page) || 1;
                    $scope.paginationOptions.itemsPerPage = (resources && resources.defaults.onpage) || 10;
                    $scope.paginationOptions.totalItems = 1;
                });
            };
        };
    }])

    .factory('contentFeedEntitiesService', ['$resource', 'serviceLocations', function ($resource, serviceLocations) {
                var key = "contentFeed",
                        actions = {};
                _.each(serviceLocations[key], function (location, type) {
                    actions[type] = {url: location, method: "GET", isArray: false};
                });
                var resources = $resource("", {}, actions);
                return {
                    get: function (type, params, success, error) {
                        var request = resources[type];
                        if (request) {
                            request(params, success, error);
                        }
                    }
                };
            }])

    .factory('GoToOrderLinks', function () {
        return {
            modify: function () {
                var $links = angular.element('body a.go-to-order');
                if($links.length > 0) {
                    $links.each(function (ind, $link) {
                        var oldLink = Polygons.parseUri($link.getAttribute('href')),
                            newLink = Polygons.parseUri(window.location.href),
                            queryObj = Object.assign(oldLink.queryKey, newLink.queryKey);
                        var queryStr = '';
                        for (var k in queryObj) {
                            queryStr += (queryStr === '' ? '?' : '&') + k + '=' + queryObj[k];
                        }
                        $link.setAttribute('href', oldLink.source + queryStr);
                    });
                }
            }
        };
    });
