angular.module('wp-front-controllers', ['ngRoute'])

    .controller('SmsController',
    ['$scope', '$rootScope', 'Sms',
        function ($scope, $rootScope, Sms) {

            $scope.wp_c_sms_init = function () {
                init(false);
            };

            $scope.wp_c_sms_verifyTrigger = function (isValid) {
                if (isValid && !$scope.wp_c_sms_disabled) {
                    verify();
                }
            };

            $scope.wp_c_sms_close = function() {
                fakeTry(true);
            };

            $scope.wp_c_sms_resend = function () {
                if (!$scope.wp_c_sms_disabled) {
                    init(true);
                }
            };

            var init = function (resend) {
                $scope.wp_c_sms_disabled = true;
                $scope.wp_c_sms_code = {id: null, value: null};

                Sms.send(resend).then(function(response) {
                    $scope.wp_c_sms_disabled = false;
                    if (response && response.attempt) {
                        $scope.wp_c_sms_code.id = response.attempt;
                        if (resend) {
                            $rootScope.wp_c_front_main_addNotice($rootScope.messages.w_sms_c_sms_form_label_accepted_send, $rootScope.NOTICE_TYPE_ACCEPTED);
                        }
                    } else if (response && response.blocked) {
                        $rootScope.wp_c_front_main_addNotice(response.message, $rootScope.NOTICE_TYPE_DISMISSED);
                    } else {
                        $rootScope.wp_c_front_main_addNotice($rootScope.messages.w_sms_c_sms_form_label_dismissed_send, $rootScope.NOTICE_TYPE_DISMISSED);
                    }
                }), function () {
                    $scope.wp_c_sms_disabled = false;
                    $rootScope.wp_c_front_main_addNotice($rootScope.messages.w_sms_c_sms_form_label_dismissed_send, $rootScope.NOTICE_TYPE_DISMISSED);
                };
            };

            var verify = function () {
                $scope.wp_c_sms_disabled = true;
                Sms.verify($scope.wp_c_sms_code.value).then(function (response) {
                    $scope.wp_c_sms_disabled = false;
                    if (response) {
                        if (response.valid) {
                            $rootScope.$broadcast('sms:verified', {pid: Sms.getPid()});
                        } else if (response.attempt_limit) {
                            $rootScope.wp_c_front_main_addNotice($rootScope.messages.w_sms_c_sms_form_label_dismissed_try, $rootScope.NOTICE_TYPE_DISMISSED);
                            $rootScope.$broadcast('sms:blocked', {pid: Sms.getPid()});
                        } else if (response.blocked) {
                            $rootScope.wp_c_front_main_addNotice(response.message, $rootScope.NOTICE_TYPE_DISMISSED);
                        } else {
                            init(false);
                            if (response['lifetime'] === false) {
                                $rootScope.wp_c_front_main_addNotice($rootScope.messages.w_sms_c_sms_form_label_dismissed_lifetime, $rootScope.NOTICE_TYPE_DISMISSED);
                            } else {
                                $rootScope.wp_c_front_main_addNotice($rootScope.messages.w_sms_c_sms_form_label_dismissed_valid, $rootScope.NOTICE_TYPE_DISMISSED);
                            }
                        }
                    } else {
                        $rootScope.wp_c_front_main_addNotice($rootScope.messages.f_common_system_error, $rootScope.NOTICE_TYPE_DISMISSED);
                        $rootScope.$broadcast('sms:failed', {pid: Sms.getPid()});
                    }
                }), function () {
                    $scope.wp_c_sms_disabled = false;
                    $rootScope.wp_c_front_main_addNotice($rootScope.messages.f_common_system_error, $rootScope.NOTICE_TYPE_DISMISSED);
                };
            };
            
            var fakeTry = function(close) {
                $scope.wp_c_sms_disabled = true;
                Sms.fakeTry().then(function() {
                    if (close) {
                        $rootScope.wp_front_main_clearNotices();
                        $rootScope.$broadcast('sms:close');
                    }
                });                            
            };
        }
    ]
)

    .controller('SocialController',
    ['$scope', 'Social', 'WpRequest',
        function ($scope, Social, WpRequest) {

            $scope.wp_c_social_services = {};

            $scope.wp_c_social_init = function () {
                initServices();
            };

            $scope.wp_c_social_signup = function (provider) {
                Social.setSignup(provider);
            };

            $scope.wp_c_social_signin = function (provider) {
                Social.setSignin(provider);
            };

            var initServices = function () {
                WpRequest._request('GET', '/request/social/getservices').then(function (response) {
                    if (response.services) {
                        $scope.wp_c_social_services = response.services;
                    }
                }), function () {
                    Polygons.log('Error getting social providers');
                };
            };
        }
    ]
)

    .controller('FrontMainController',
    ['$scope', '$rootScope','$window', '$uibModal', '$timeout', 'Theme','Auth','Settings',
        function ($scope, $rootScope,$window, $uibModal, $timeout, Theme,Auth,Settings) {
            var modalInstance;

            $rootScope.wp_extended_form_locked = false;
            $rootScope.wp_extended_form_email_pattern = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            $rootScope.NOTICE_TYPE_ACCEPTED = 'accepted';
            $rootScope.NOTICE_TYPE_DISMISSED = 'dismissed';
            $rootScope.wp_extended_form_notices = {};

            $rootScope.wp_c_front_main_addNotice = function (message, type) {
                var index = _.size($rootScope.wp_extended_form_notices);
                var i = index++;
                $rootScope.wp_extended_form_notices[i] = {message: message, type: type};

                $timeout(function () {
                    delete $rootScope.wp_extended_form_notices[i];
                }, 5000);
            };
            
            $rootScope.wp_front_main_clearNotices = function() {
                $rootScope.wp_extended_form_notices = {};
            };

            var temp_directory='/uploaded/temp/';
            var param_delemitter = '_x_';
            var image_directory_delemitter='-_y_-';


            $rootScope.wp_front_main_getImageSrc=function(width, heigth, image_base_src, notresize){
                if (typeof(image_base_src) == 'string') {
                if (!notresize) {
                    var t=[width,heigth,image_base_src.replace(/\//g, image_directory_delemitter)];
                    return temp_directory + t.join(param_delemitter);
                } else {
                     return '/'.image_base_src;
                }
            }  
            return false;
            };


            $scope.wp_c_front_main_init = function () {
                renderTheme();
            };

            var renderTheme = function () {
                Theme.getPersonal().then(function (response) {
                    if (!response.error) {
                        var theme = response.data[0];
                        if (theme.theme_style) {
                            if (angular.element('#theme-link-stylesheet')) {
                                angular.element('#theme-link-stylesheet').remove();
                            }
                            angular.element(document.head).append('<link id="theme-link-stylesheet" rel="stylesheet" type="text/css" href="' + theme.theme_style + '">');
                        }
                    }
                });
            };

            $rootScope.$on('signin:signed', function () {
                renderTheme();
            });


            $rootScope.$on('sms:process', function (event, data) {
                $rootScope.wp_extended_form_locked = true;
                modalInstance = $uibModal.open({
                    templateUrl: $rootScope.wpPublishDir+'templates/modals/modal_sms.html',
                    backdrop: 'static',
                    keyboard: false
                });
            });

            $rootScope.$on('sms:close', function () {
                modalInstance.close();
                $rootScope.wp_extended_form_locked = false;
            });

            $rootScope.$on('sms:verified', function () {
                modalInstance.close();
                $rootScope.wp_extended_form_locked = false;
            });
            
           $scope.$on('auth:signout', function (event, data) {
             Auth.signout().then(function (data) {
                        Auth.credentials = data.credentials;
                        Settings.getProperty('signin_alias').then(function(data){
                              $window.location.assign(data.value);
                        });
                 renderTheme();
                }), function () {
                    Polygons.log('frontside_header: auth error');
                };
            });
              
        }
    ]
);
