angular.module('wp-common-controllers', ['ngRoute', 'wp-common-services'])

    .controller('MainController', ['$scope', '$rootScope', '$http', '$location', 'region','WPMessages',
        function ($scope, $rootScope, $http, $location, region,WPMessages) {

            WPMessages.getMessages().then(function (data) {
                    $rootScope.messages = data.data;
            });
            

            $rootScope.wpVersion = Polygons.wpVersion;

            $rootScope.wpWidgetsDir = Polygons.getAppAssetsDir('widgetsDir');
            $rootScope.wpPublishDir = Polygons.getAppAssetsDir('appDir');

            $scope.wp_c_main_regions = {};

            var arraysEqual = function (array1, array2) {
                return angular.toJson(array1) === angular.toJson(array2);
            };


            var getPath = function (obj) {
                var path = undefined;
                if (obj && obj.originalPath) {
                    path = obj.originalPath;
                    if (path.length > 1) {
                        path = path.replace(/^\/|\/$/g, '');
                    }
                }
                return path;
            };

                    $scope.$on('$routeChangeSuccess', function (event, next, current) {
                        var nextRegions = Polygons.getRegionsByPath(getPath(next)) || [],
                                currentRegion = Polygons.getRegionsByPath(getPath(current)) || [],
                                reloadedRegions = [], deletedRegions = [], i;

                        for (i in nextRegions) {
                            if (!arraysEqual(nextRegions[i], currentRegion[i])) {
                                reloadedRegions.push(i);
                                deletedRegions.push(i);
                            }
                        }

                        for (i in currentRegion) {
                            if (!arraysEqual(nextRegions[i], currentRegion[i]) && $.inArray(i, reloadedRegions) === -1) {
                                deletedRegions.push(i);
                            }
                        }

                        if (deletedRegions.length > 0) {
                            for (i in deletedRegions) {
                                try {
                                    delete $scope.wp_c_main_regions[deletedRegions[i]];
                                } catch (e) {
                                    $scope.wp_c_main_regions[deletedRegions[i]] = undefined;
                                }
                            }
                        }

                        if (reloadedRegions.length > 0) {
                            var l;
                            $http.get(Polygons.url.initPagePath, {
                                params: {
                                    path: $location.path(),
                                    attr: Polygons.modelattr['page'],
                                    'reloadedregions[]': reloadedRegions
                                }
                            }).then(function (data) {
                                if (data != undefined && data.data != undefined) {
                                    $rootScope.page = data.data.page;
                                    region.initRegions(data.data.regions).then(function () {
                                        for (l in data.data.regions) {
                                            $scope.wp_c_main_regions[l] = data.data.regions[l];
                                        }
                                    });
                                }
                            }, function (data, status) {
                                Polygons.log(data);
                            });
                        } else {
                            $rootScope.$broadcast("wp_common_directives_loading_remove_class");
                        }
                    });


        }]);