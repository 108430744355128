angular.module('wp-common-services', [])
    .provider('controllerResources', createCommonProvider())
    .provider('serviceLocations', createCommonProvider())
    .provider('directiveResources', createCommonProvider())
    .factory('WpRequest', ['$http', function ($http) {
        return {
            _request: function (method, url, params) {
                var parameter = {};
                if (method == "POST") {
                    var headers = {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    };
                    parameter = {
                        method: method,
                        url: url,
                        data: params,
                        headers: headers
                    };
                } else if (method == "GET") {
                    parameter = {
                        method: method,
                        url: url,
                        params: params
                    };
                }

                return $http(parameter).then(function (response) {
                    return response.data;
                }, function (response) {
                    return response.data;
                });
            }
        };
    }])
    .factory('element', function () {
        var Element = {};

        Element.element_type = {
            layout: 1,
            widget: 2
        };

        var layoutsMap = {
            1: 'layout.html',
            2: 'widget.html'
        };


        return {
            element_type: {
                layout: 1,
                widget: 2
            },
            getElementLayout: function (elementId) {
                return layoutsMap[elementId];
            },
            layoutGetColumnElement: function (columnId, layoutElements) {
                var elements = [],
                    i;
                for (i in layoutElements) {
                    if (layoutElements[i].element_options.element_in_column == columnId) {
                        elements.push(layoutElements[i]);
                    }
                    if (layoutElements[i].element_parent_column_id == 'col' + columnId) {
                        elements.push(layoutElements[i]);
                    }
                }
                return elements;
            }
        };
    })

    .factory('WidgetsDefer', function () {
        var widgetsDefers = {}, widgetsPromises = {};
        var issetDefer = function (widget_key) {
            return widgetsDefers[widget_key] !== undefined;
        };
        return {
            resetDefers: function () {
                widgetsDefers = {};
                widgetsPromises = {};
            },
            resolveDefer: function (widget_key) {
                if (issetDefer(widget_key)) {
                    widgetsDefers[widget_key].resolve();
                }
            },
            setDefer: function (widget_key, defer) {
                widgetsDefers[widget_key] = defer;
                widgetsPromises[widget_key] = defer.promise;
            },
            getPromises: function () {
                return widgetsPromises;
            }
        };
    })

    .factory('region', ['$q', 'element', 'WidgetsDefer', function ($q, elementService, WidgetsDefer) {

        var _region = {
            _includedJS: [],
            _includedCSS: [],
            initElement: function (element) {
                var _def = $q.defer(), _defArray = [];
                if (element.element_type_id == elementService.element_type.widget) {
                    _defArray.push(this.initWidget(element));
                } else if (element.element_type_id == elementService.element_type.layout) {
                    if (element.elements != null && element.elements.length > 0) {
                        for (var i in element.elements) {
                            _defArray.push(this.initElement(element.elements[i]));
                        }
                    }
                }
                $q.all(_defArray).then(function () {
                    _def.resolve();
                });
                return _def.promise;
            },
            loadResource: function (url, type, callback) {
                if (!url || !(typeof url === 'string')) {
                    return;
                }
                if (type != 'link' && type != 'script') {
                    return;
                } else {
                    var el = document.createElement(type);
                    //if this is IE8 and below, handle onload differently
                    if (typeof document.attachEvent === "object") {
                        el.onreadystatechange = function () {
                            //once the script is loaded, run the callback
                            if (el.readyState === 'loaded' || el.readyState === 'complete') {
                                el.onreadystatechange = null;
                                if (callback) {
                                    callback();
                                }
                            }
                        };
                    } else {
                        //this is not IE8 and below, so we can actually use onload
                        el.onload = function () {
                            //once the script is loaded, run the callback
                            if (callback) {
                                callback();
                            }
                        };
                    }

                    if (type === 'link') {
                        el.type = "text/css";
                        el.rel = "stylesheet";
                        el.href = url + "?" + Polygons.wpVersion;
                    }
                    if (type === 'script') {
                        el.src = url + "?" + Polygons.wpVersion;
                    }
                    document.getElementsByTagName('head')[0].appendChild(el);
                    /// hack for safari brouser
                    if (Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0 && type === 'link') {
                        var img = document.createElement('img');
                        img.onerror = function () {
                            if (callback) {
                                callback();
                            }
                        };
                        img.src = url;
                    }

                }
            },
            initArrayFiles: function (type, arrayFiles, storageFiles, storagePromise) {
                var el, i;
                if (arrayFiles !== undefined && arrayFiles.length > 0) {
                    for (i in arrayFiles) {
                        arrayFiles[i] = Polygons.getmodePathUrl(arrayFiles[i]);
                        if (jQuery.inArray(arrayFiles[i], storageFiles) === -1) {
                            el = (function (file) {
                                var tmpDef = $q.defer();
                                _region.loadResource(file, type, function () {
                                    tmpDef.resolve();
                                });
                                return tmpDef.promise;
                            })(arrayFiles[i]);
                            storageFiles.push(arrayFiles[i]);
                            storagePromise.push(el);
                        }
                    }
                }
            },
            initWidget: function (widget) {
                var _def = $q.defer(), _defW = [];
                WidgetsDefer.setDefer(widget.element_options.uniq_id, $q.defer());
                this.initArrayFiles('link', widget.css, this._includedCSS, _defW);
                this.initArrayFiles('script', widget.libs, this._includedJS, _defW);
                this.initArrayFiles('script', widget.js, this._includedJS, _defW);
                $q.all(_defW).then(function () {
                    _def.resolve();
                });

                return _def.promise;
            }
        };

        return {
            initRegions: function (regions) {
                var _d = $q.defer(),
                    _def = [];
                for (var i in regions) {
                    for (var y in regions[i]) {
                        _def.push(_region.initElement(regions[i][y]));
                    }
                }
                $q.all(_def).then(function () {
                    _d.resolve();
                });
                return _d.promise;
            }
        };

    }])

    .factory('ArrayHelper', function () {
        var ArrayHelper = {};

        ArrayHelper.inArray = function (needle, haystack, strict) {
            var found = false, key, strict = !!strict;
            for (key in haystack) {
                if ((strict && haystack[key] === needle) || (!strict && haystack[key] == needle)) {
                    found = true;
                    break;
                }
            }
            return found;
        };


        ArrayHelper.searchKeyArray = function (needle, haystack, strict) {
            var found = false, key, strict = !!strict;
            for (key in haystack) {
                if ((strict && haystack[key] === needle) || (!strict && haystack[key] == needle)) {
                    found = key;
                    break;
                }
            }
            return found;
        };

        ArrayHelper.searchLabelByValueArray = function (needle, haystack, id_label, id_value, strict) {
            var searchValue = function (needle, haystack, id_label, id_value, strict) {
                var found = '', key, strict = !!strict, currentvalue;
                for (key in haystack) {
                    currentvalue = haystack[key][id_value];
                    if ((strict && currentvalue === needle) || (!strict && currentvalue == needle)) {
                        found = haystack[key][id_label];
                        break;
                    }
                }
                return found;
            };
            var label = '', i;
            if (Object.prototype.toString.call(needle) === '[object Array]') {
                for (i in needle) {
                    label = label + searchValue(needle[i], haystack, id_label, id_value, strict) + ' ';
                }
            } else if (typeof needle === 'string' || typeof needle === 'number') {
                label = searchValue(needle, haystack, id_label, id_value, strict);
            }
            return label;
        };

        ArrayHelper.remove = function (arr, item) {
            for (var i = arr.length; i--;) {
                if (arr[i] === item) {
                    arr.splice(i, 1);
                    break;
                }
            }
        };


        return ArrayHelper;
    })

    .factory('Pager', ['$cookies', function ($cookies) {
        var onPage = $cookies.get('wpPagerOnPage');
        return {
            current_page: 1,
            onpage: onPage || 10,
            total_object: 0
        };
    }])

    .factory('Theme', ['WpRequest', function (WpRequest) {
        var Theme = {};

        //get choice for personal profile
        Theme.getAvailable = function () {
            return WpRequest._request("GET", "/request/themes/getAvailableThemes", {});
        };

        //save choosen theme
        Theme.setPersonal = function (id) {
            return WpRequest._request("POST", "/request/themes/setUserTheme", {Theme: {id: id}});
        };

        //get personal theme on login/logout action
        Theme.getPersonal = function () {
            return WpRequest._request("GET", "/request/themes/getUserTheme", {});
        };

        return Theme;
    }])

    .factory('Kladr', ['WpRequest', function (WpRequest) {
        var Kladr = {},
            _codes = {
                location_id: null,
                region_id: null,
                city_id: null
            };

        Kladr.getMatches = function (keyword, type, clear) {
            var parent_id = null;
            switch (type) {
                case 1:
                    parent_id = clear ? null : _codes.location_id;
                    break;
                case 2:
                    parent_id = clear ? null : _codes.region_id ? _codes.region_id : _codes.location_id;
                    break;
                default:
                    break;
            }

            return WpRequest._request("POST", "/request/kladr/getmatches", {
                keyword: keyword,
                object_type: type,
                parent_id: parent_id
            });
        };

        Kladr.getTimezone = function () {
            _codes.sibiling_id = _codes.region_id ? _codes.region_id : _codes.city_id;
            return WpRequest._request("POST", "/request/kladr/getTimezone", _codes);
        };

        Kladr.setCode = function (type, value) {
            var key;
            switch (type) {
                case 0:
                    key = 'location_id';
                    break;
                case 1:
                    key = 'region_id';
                    break;
                case 2:
                    key = 'city_id';
                    break;
                default:
                    break;
            }
            _codes[key] = value;
        };

        Kladr.saveData = function (location, region, city) {
            delete _codes.sibiling_id;

            _codes.location_id = location;
            _codes.region_id = region;
            _codes.city_id = city;
        };

        return Kladr;
    }])

    .factory('Settings', ['Systemproperty', '$q', function (Systemproperty, $q) {
        var property_collector = {};

        return {
            getProperty: function (key) {
                var handleDeferred = $q.defer();

                if (property_collector[key] === undefined) {
                    Systemproperty.query({key: key}, function success(data) {
                        property_collector[key] = data.value;
                        handleDeferred.resolve({value: property_collector[key]});
                    }, function error(data) {
                        Polygons.log('System properties: key fetch error, ', data);
                        handleDeferred.reject({value: null});
                    });
                } else {
                    handleDeferred.resolve({value: property_collector[key]});
                }

                return handleDeferred.promise;
            },
            resetProperty: function (key) {
                if (property_collector[key] !== undefined) {
                    delete property_collector[key];
                }
            },
            getProperties: function (keys) {
                var handleDeferred = $q.defer(),
                    i = keys.length,
                    result = {};

                for (i; i; i--) {
                    var j = i - 1;
                    if (property_collector[keys[j]] !== undefined) {
                        result[keys[j]] = property_collector[keys[j]];
                        keys.pop();
                    }
                }
                if (keys.length) {
                    Systemproperty.query({'keys[]': keys}, function success(data) {
                        for (var z in keys) {
                            result[keys[z]] = data.values[keys[z]];
                        }
                        handleDeferred.resolve({values: result});
                    }, function error(data) {
                        Polygons.log('System properties: key fetch error, ', data);
                        handleDeferred.reject({values: null});
                    });
                } else {
                    handleDeferred.resolve({values: result});
                }

                return handleDeferred.promise;
            }
        };
    }])

    .factory('SystemMessage', ['WpRequest', '$cookieStore', function (WpRequest, $cookieStore) {
        var Obj = {};
        Obj.types = {
            //text: 0,
            confirmation: 1,
            recovery: 2
        };
        var _activity = {
            confirmation: {ref: null, value: null},
            recovery: {ref: null, value: null}
        };

        var _opts = {
            type: 0,
            sender: null,
            recipient_id: null
        };

        Obj.checkActivity = function () {
            var store = $cookieStore.get('email_confirmation');
            if (store) {
                $cookieStore.remove('email_confirmation');
                _activity.confirmation = {ref: true, value: store.value};
            }

            store = $cookieStore.get('password_recovery');
            if (store) {
                $cookieStore.remove('password_recovery');
                _activity.recovery = {ref: true, value: store.value};
            }
        };

        Obj.getActivityOptions = function (type) {
            return type ? _activity[_.invert(Obj.types)[type]] : _activity;
        };

        Obj.setOptions = function (type, recipient_id, sender) {
            _opts.type = type;
            _opts.recipient_id = recipient_id;
            _opts.sender = sender;
        };

        Obj.send = function () {
            return WpRequest._request("POST", "/request/validation/sendmessage", _opts);
        };

        return Obj;
    }])

    .factory('responseAnalyzer', ['$rootScope', function ($rootScope) {
        var alalyzeSeo = function (data) {
            var ret;
            for (var i in data) {
                if (i === 'seo') {
                    $rootScope.seo = data[i];
                    return true;
                } else {
                    if (typeof data[i] === 'object') {
                        ret = alalyzeSeo(data[i]);
                    }
                    if (ret)
                        return true;
                }
            }
            return false;
        };
        return {
            analyze: function (response) {
                if (typeof response.data === 'object') {
                    var string = angular.toJson(response.data);
                    if (string.indexOf('"seo":', 0) > -1) {
                        alalyzeSeo(response.data);
                    }
                }
            }
        };
    }])
    .factory('WPMessages', ['$q', '$http', '$cookies', function ($q, $http, $cookies) {

        var defaultLanguage = 'en', langs = ['en', 'ru'];
        var lang = $cookies.get('language') || defaultLanguage;

        var storage = sessionStorage,
            messages = angular.fromJson(storage.WPMessages);

        return {
            getMessages: function () {
                if (!messages) {
                    return $http({
                        url: "/request/main/getmessages",
                        method: "GET"
                    }).success(function (data) {
                        storage.WPMessages = angular.toJson(data);
                    });
                } else {
                    var deferred = $q.defer();
                    deferred.resolve({data: messages});
                    return deferred.promise;
                }
            },
            setLanguage: function (lang) {
                // set date current +365 days.
                var expireDate = new Date();
                expireDate.setDate(expireDate.getDate() + 365);
                $cookies.put('language', lang, {'expires': expireDate});
                storage.removeItem('WPMessages');
            },
            getNotActiveLanguage: function () {
                for (var i in langs) {
                    if (langs[i] !== lang) {
                        return langs[i];
                    }
                }
            }
        };
    }])
    .factory('WPGet', [function () {
        var vars = {},
            parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
                vars[key] = value;
            });

        return {
            getParam: function (key) {
                return vars[key] ? vars[key] : null;
            },
            getUtmParams: function () {
                var utmParams = {};
                for (var k in vars) {
                    if (k.indexOf('utm_') === 0) {
                        utmParams[k] = vars[k];
                    }
                }
                return utmParams;
            }
        };
    }]);

function createCommonProvider() {
    return function () {
        var data = {};
        return {
            add: function (name, item) {
                data[name] = item;
            },
            $get: function () {
                return data;
            }
        };
    };
}
