var scripts = document.getElementsByTagName('script');

var urls = {
    routeSegmentPath: "/request/frontconstructor/getPathes",
    initPagePath: "/request/frontconstructor/initPage",
};

var modelsattr = {
    page: 'page_id~alias~page_title~page_meta_description~page_meta_keyword~page_script~page_adaptive|seo>seo_object_title~seo_object_meta_description~seo_object_meta_keyword',
    news: 'date_day_ru~date_month_ru~id~model~news_link~news_name~news_publication_date~news_short_text~news_text|seo>seo_object_title~seo_object_meta_description~seo_object_meta_keyword',
    simple: 'id~model~simple_name~simple_text',
    articles: 'id~model~article_text~article_name|seo>seo_object_title~seo_object_meta_description~seo_object_meta_keyword',
    banner: 'id~model~banner_name~banner_description',
    contentmenu: 'id~model~content_menu_name~menu_items',
    planned: 'id~model~payment~is_new~planned_p_user_id~planned_p_payment_id~planned_p_amount~planned_p_cards~planned_p_debit_from_kart~planned_p_debit_from_kart_id~planned_p_id~planned_p_monthly_day_payments~' +
            'planned_p_monthly_every_month_number~planned_p_monthly_every_week_number~planned_p_monthly_type_id~planned_p_name~planned_p_once_date~planned_p_periodicity_type_id~' +
            'planned_p_send_notification_before_days~planned_p_send_notification_by_email~planned_p_send_notification_by_sms~planned_p_start_payment_date~planned_p_status_id~' +
            'planned_p_stop_count~planned_p_once_date~planned_p_stop_type_id~planned_p_user_email~planned_p_user_phone~planned_p_week_days~planned_p_weekly_every_week_number~planned_p_stop_date',
    paymenttemplate: 'id~is_new~model~payment~payment_template_amount~payment_template_id~payment_template_name~payment_template_payment_id'
};

Polygons.setAppAssetsDir('appDir',Polygons.getScriptDir(scripts[scripts.length - 1]));
Polygons.setAppAssetsDir('widgetsDir',Polygons.getScriptDir(scripts[scripts.length - 1])+'widget/');
Polygons.setUrlsPath(urls);
Polygons.setModelattr(modelsattr);