angular.module('wp-front-rest-models', ['ngResource'])
    .factory('Users', ['$resource', function ($resource) {
        return $resource('/users/api/:model/:id', {model: "users", id: "@id"}, {
            update: {method: 'PUT', params: {id: "@id"}, isArray: false},
            query: {method: 'GET', isArray: false}});
    }])
    .factory('Simple', ['$resource', function ($resource) {
        return $resource('/content/api/:model/:id', {model: "simple", id: "@id"},
            {query: {method: 'GET',params:{attr:Polygons.modelattr['simple']}, isArray: false}});
    }])
    .factory('Articles', ['$resource', function ($resource) {
        return $resource('/content/api/:model/:id', {model: "articles", id: "@id"},
            {query: {method: 'GET',params:{attr:Polygons.modelattr['articles']}, isArray: false}});
    }])
    .factory('News', ['$resource', function ($resource) {
        return $resource('/content/api/:model/:id', {model: "news", id: "@id"},
            {query: {method: 'GET', params:{attr:Polygons.modelattr['news']}, isArray: false}});
    }])
    .factory('Banner', ['$resource', function ($resource) {
        return $resource('/content/api/:model/:id', {model: "banner", id: "@id"},
            {query: {method: 'GET',params:{attr:Polygons.modelattr['banner']}, isArray: false}});
    }])
    .factory('Contentmenu', ['$resource', function ($resource) {
        return $resource('/content/api/:model/:id', {model: "contentmenu", id: "@id"},
            {query: {method: 'GET',params:{attr:Polygons.modelattr['contentmenu']}, isArray: false}});
    }])
    .factory('Offers', ['$resource', function ($resource) {
        return $resource('/content/api/:model/:id', {model: "offers", id: "@id"},
            {query: {method: 'GET', isArray: false}});
    }])
    .factory('Uploads', ['$resource', function ($resource) {
        return $resource('/systemp/api/:model/:id', {model: "uploads", id: "@id"}, {
            query: {method: 'GET', isArray: false},
            del: {method: 'DELETE', params: {id: "@id"}, isArray: false}
        });
    }])
    .factory('Planned', ['$resource', function ($resource) {
        return $resource('/payments/api/:model/:id', {model: "planned", id: "@id"}, {
            query: {method: 'GET', params:{attr:Polygons.modelattr['planned']}, isArray: false},
            del: {method: 'DELETE', params: {id: "@id"}, isArray: false}
        });
    }])
    .factory('Systemproperty', ['$resource', function ($resource) {
        return $resource('/systemp/api/:model/:id', {model: "systemproperty", id: "@id"}, {
            query: {method: 'GET', isArray: false}
        });
    }])
    .factory('Paymenttemplates', ['$resource', function ($resource) {
        return $resource('/payments/api/:model/:id', {model: "paymenttemplate", id: "@id"}, {
            query: {method: 'GET',params:{attr:Polygons.modelattr['paymenttemplate']}, isArray: false},
            del: {method: 'DELETE', params: {id: "@id"}, isArray: false}
        });
    }])
    .factory('Cards', ['$resource', function ($resource) {
        return $resource('/payments/api/:model/:id', {model: "cards", id: "@id"}, {
            query: {method: 'GET', isArray: false},
            view: {method: 'GET', params: {id: "@id"}, isArray: false},
            del: {method: 'DELETE', params: {id: "@id"}, isArray: false}
        });
    }])
    .factory('UrlRelations', ['$resource', function ($resource) {
        return $resource('/systemp/api/:model', {model: "urlrelations"}, {
            urls: {method: 'GET', isArray: false}
        });
    }]);