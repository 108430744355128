angular.module('wp-front-directives', [])

    .directive('wpinput', ['$rootScope', function ($rootScope) {
        return {
            restrict: 'E',
            require: '?inputvalue',
            templateUrl: function (tElement, tAttrs) {
                var baseUrl = $rootScope.wpPublishDir + 'templates/directives/inputs/',
                    templateMap = {
                        text: 'input-text.html',
                        password: 'input-password.html',
                        select: 'input-select.html',
                        phone: 'input-masked-phone.html',
                        email: 'input-email.html'
                    };
                return baseUrl + templateMap[tAttrs.inputType];
            },
            scope: {
                optional: '=',
                reg: '=',
                code: '=',
                labelname: '@',
                inputvalue: '=',
                inputerror: '=',
                selectvalue: '=',
                eventHandler: '&ngClick'
            },
            link: function (scope, elm, attrs, ctrl) {
            }
        };
    }])

    .directive('wpi', [function () {
        return {
            restrict: 'A',
            scope: {
                field: '='
            },
            require: '?ngModel',
            link: function (scope, elm, attrs, ctrl) {
                if (!ctrl) {
                    return;
                }// do nothing if no ng-model

                attrs.$set('name', '' + scope.field.code);
                attrs.$set('ngModel', '' + scope.field.code);
                ctrl.$name = '' + scope.field.code;

                if (scope.field.optional == '0') {
                    ctrl.$parsers.unshift(function (viewValue) {
                        if (ctrl.$isEmpty(viewValue)) {
                            ctrl.$setValidity('required', !1);
                            return undefined;
                        } else {
                            ctrl.$setValidity('required', !0);
                            return viewValue;
                        }
                    });
                    ctrl.$error.required = !1;
                }

                if (scope.field.reg /*&& !scope.field.mask*/) {
                    var reg = new RegExp(scope.field.reg);
                    ctrl.$parsers.unshift(function (viewValue) {
                        if (ctrl.$isEmpty(viewValue) || reg.test(viewValue)) {
                            ctrl.$setValidity('reg', !0);
                            return viewValue;
                        } else {
                            ctrl.$setValidity('reg', !1);
                            return undefined;
                        }
                    });
                    ctrl.$error.reg = !1;
                }

                if (scope.field.def) {
                    for (var i in scope.field.def) {
                        ctrl.$setViewValue(scope.field.def[i].value);
                        ctrl.$modelValue = scope.field.def[i].value;
                    }
                }

                attrs.$set('field', null);
            }
        };
    }])

    .directive('ngFocus', [function () {
        var FOCUS_CLASS = "ng-focused";
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                ctrl.$focused = false;
                element.on('focus', function (evt) {
                    element.addClass(FOCUS_CLASS);
                    scope.$evalAsync(function () {
                        ctrl.$focused = true;
                    });
                }).on('blur', function (evt) {
                    element.removeClass(FOCUS_CLASS);
                    scope.$evalAsync(function () {
                        ctrl.$focused = false;
                    });
                }).on('mouseenter', function (evt) {
                    element.addClass(FOCUS_CLASS);
                    scope.$evalAsync(function () {
                        ctrl.$focused = true;
                    });
                }).on('mouseleave', function (evt) {
                    element.removeClass(FOCUS_CLASS);
                    scope.$evalAsync(function () {
                        ctrl.$focused = false;
                    });
                });
            }
        };
    }])

    .directive('wpUnique', ['$http', function ($http) {
        return {
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                var filterAction = function () {
                    $http({
                        method: 'POST',
                        url: '/request/validation/unique/',
                        params: {property: attrs.wpUnique, value: ctrl.$modelValue}
                    }).success(function (data, status, headers, cfg) {
                        ctrl.$setValidity('unique', data.unique);
                    }).error(function (data, status, headers, cfg) {
                        ctrl.$setValidity('unique', false);
                    });
                };

                var filterDelayed = function () {
                    scope.$apply(function () {
                        filterAction();
                    });
                };
                var filterThrottled = _.debounce(filterDelayed, 400);

                scope.$watch(attrs.ngModel, function () {
                    if (ctrl.$modelValue) {
                        filterThrottled();
                    }
                });
            }
        };
    }])

    .directive('wpSwiper', ['$timeout', function ($timeout) {
        var stopSwipe = function (el) {
            var ul = el.find('>ul'),
                ul_width = ul.width(),
                nav_width = el.width();

//            alert(ul_width + '-' + nav_width);

            if (ul_width <= nav_width) {
                ul.addClass('not-swipe');
            } else {
                ul.removeClass('not-swipe');
            }
        };
        return {
            link: function ($scope, $element) {
                $timeout(function () {
                    $element.swiper({
                        slidesPerView: 'auto',
                        calculateHeight: true,
                        grabCursor: true,
                        //freeMode: true,
                        //freeModeFluid: true,
                        //noSwiping: true,
                        //noSwipingClass: "not-swipe",
                        //useCSS3Transforms: false,
                        onSwiperCreated: function () {
                            $timeout(function () {
                                stopSwipe($element);
                            }, 100);
                        }
                    });
                }, 0);

                $(window).resize(function () {
                    stopSwipe($element);
                });
            }
        };
    }])

    .directive('wpStrongPass', [function () {
        var isStrong = function (s) {
            var reg = /(?=.*?[a-z|A-Z])(?=.*?[0-9])/;
            return reg.test(s);
        };

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                ctrl.$parsers.unshift(function (viewValue) {
                    ctrl.$setValidity('strongPass', isStrong(viewValue));
                    return viewValue;
                });
                ctrl.$formatters.unshift(function (modelValue) {
                    ctrl.$setValidity('strongPass', isStrong(modelValue));
                    return modelValue;
                });
            }
        };
    }])

    .directive('wpFormMessage', ['$rootScope', function ($rootScope) {
        return {
            restrict: 'A',
            templateUrl: $rootScope.wpPublishDir + 'templates/directives/alerts/alert.html',
            transclude: true,
            replace: true,
            scope: {
                type: '@', //use 'accepted' or 'dismissed' style-types in frontend forms for alert messages
                close: '&'
            },
            controller: ['$scope', '$attrs', function ($scope, $attrs) {
                $scope.closeable = 'close' in $attrs;
                $scope.status = true;
            }]
        };
    }])

    .directive('wpMessage', ['$timeout', '$rootScope', function ($timeout, $rootScope) {
        return {
            restrict: 'A',
            template: function () {
                return "" +
                    "<div class=\"validation-message wp-animate ng-hide\" data-ng-class=\"message.msgType\" data-ng-show=\"msgShow\">\n" +
                    "   <i class=\"validation-message-icon\">&nbsp;</i>\n" +
                    "   <p class=\"validation-message-text\" data-ng-bind-html=\"message.msgText\">\n" +
                    "   </p>\n" +
                    "   <span class=\"validation-message-close\" data-ng-click=\"hideMessage()\" data-ng-if=\"msgCloseBtn\">&nbsp;</span>\n" +
                    "</div>";
            },
            replace: true,
            require: 'wpMessage',
            //scope: {
            //    message: "=wpMessage"
            //},
            controller: ['$scope', '$attrs', function ($scope, $attrs) {

                var owner,
                    timeout,
                    hideMessage = function () {
                        $scope.msgShow = false;
                        $scope.message = undefined;
                        $rootScope[owner] = undefined;
                        owner = undefined;
                        $timeout.cancel(timeout);
                    };

                $scope.msgShow = false;
                $scope.msgCloseBtn = 1;

                $scope.hideMessage = function () {
                    hideMessage();
                };

                this.showMessage = function (message) {
                    owner = this.owner;
                    $scope.message = message;
                    $scope.msgShow = true;

                    $scope.msgCloseBtn = this['CloseBtn'] !== undefined ? this['CloseBtn'] : 1;

                    $timeout(function () {
                        var top = $('.validation-message').first().offset()['top'];
                        if (top) {
                            $('html, body').animate({
                                scrollTop: top - 40
                            }, 300);
                        }
                    });

                    timeout = $timeout(function () {
                        hideMessage();
                    }, this.TimeLimit !== undefined ? (this.TimeLimit < 1 ? 10000000 : this.TimeLimit) : 5000);
                };

            }],
            link: function ($scope, element, $attrs, ctrl) {
                var watchAttr = $attrs.wpMessage;

                ctrl.owner = $attrs.wpMessage;

                if ($attrs.wpMessageTimeout !== undefined) {
                    ctrl.TimeLimit = $attrs.wpMessageTimeout;
                }

                if ($attrs.wpMessageCloseBtn !== undefined) {
                    ctrl.CloseBtn = +$attrs.wpMessageCloseBtn;
                }

                $rootScope.$watchCollection(watchAttr, function (value) {
                    if (value !== undefined) {
                        ctrl.showMessage(value);
                    }
                });

                //$scope.$watchCollection('message', function (value) {
                //    if (value !== undefined) {
                //        ctrl.showMessage();
                //    }
                //});
            }
        };
    }])

    .directive("bindCompiledHtml", ['$compile', function ($compile) {
        return {
            scope: {
                rawHtml: '=bindCompiledHtml'
            },
            link: function (scope, element, attrs) {
                scope.$watch('rawHtml',
                    function (value) {
                        // when the 'compile' expression changes
                        // assign it into the current DOM
                        element.html(value);

                        // compile the new DOM and link it to the current
                        // scope.
                        // NOTE: we only compile .childNodes so that
                        // we don't get into infinite loop compiling ourselves
                        $compile(element.contents())(scope);
                    }
                );
            }
        };
    }])

    .directive('navResize', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            controller: ['$scope', '$element', function ($scope, $element) {
                $scope.ul_width = 0;
                $scope.nav_width = 0;
//                $scope.ul = $('nav ul');
//                $scope.ul = $element;

                $scope.wp_w_frontside_footer_d_nav_resize = function (ul_width, nav_width) {
                    $scope.ul_width = ul_width;
                    $scope.nav_width = nav_width;
//                    alert(ul_width + ' - ' + nav_width);
                    if (ul_width <= nav_width) {
//                        alert('1');
                        $element.siblings('ul').addClass('not-swipe');
                    } else {
//                        alert('2');
                        $element.siblings('ul').removeClass('not-swipe');
                    }
                };
            }],
            link: function ($scope, $element, attrs) {
                var element = $element.siblings('ul');
                (function (el) {
                    $timeout(function () {
//                        alert(el.width() + ' - ' + el.parent().width());
                        $scope.wp_w_frontside_footer_d_nav_resize(el.width(), el.parent().width());
                    }, 100);
                })(element);

                $(window).resize(function () {
                    $scope.wp_w_frontside_footer_d_nav_resize(element.width(), element.parent().width());
                });
            }
        };
    }])

    .directive('wpPasteDeny', [function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                var PASTE_CLASS = "wp-pasted";
                var ctrlDown = false;
                ctrl.$pasted = false;

                element.keydown(function (event) {
                    if (event.which == 17) {
                        ctrlDown = true;
                    }

                    if (ctrlDown && (event.which == 86)) {
                        event.preventDefault();
                        element.addClass(PASTE_CLASS);
                        scope.$apply(function () {
                            ctrl.$pasted = true;
                        });
                    } else {
                        element.removeClass(PASTE_CLASS);
                        scope.$apply(function () {
                            ctrl.$pasted = false;
                        });
                    }
                }).keyup(function (event) {
                    if (event.which == 17) {
                        ctrlDown = false;
                    }
                });
            }
        };
    }])

    .directive('setActiveLink', ['$timeout', '$location', function ($timeout, $location) {
        return {
            restrict: 'A',
            replace: false,
            link: function (scope, element, attrs) {
                $timeout(function () {
                    element.find('a[href="' + $location.path() + '"]').parent().addClass('submenu-step-active');
                }, 50);
            }
        };
    }])

    .directive('wpScrollDropdown', [function () {
        return {
            restrict: 'A',
            link: function ($scope, $element, attrs) {
                var MIN_WIDTH = 700;

                $element.bind('keydown', function () {
                    var width = $(window).width();
                    if (width <= MIN_WIDTH) {
                        document.body.scrollTop = $element.offset().top;

                        $(document).click(function () {
                            document.body.scrollTop = 0;
                        });
                    }
                });
            }
        };
    }])

    .directive('smoothScroll', ['$window', function ($window) {
        var currentPosition = function () {
            if ($window.pageYOffset) {
                return $window.pageYOffset;
            }
            if ($window.document.documentElement && $window.document.documentElement.scrollTop) {
                return $window.document.documentElement.scrollTop;
            }
            if ($window.document.body && $window.document.body.scrollTop) {
                return $window.document.body.scrollTop;
            }
            return 0;
        };

        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                element.on('keydown', function () {
                    var MIN_WIDTH = 700;
                    var currPos, topOffset, header;

                    currPos = currentPosition();
                    topOffset = element.offset().top;
                    header = $('header').outerHeight();

                    if ($(window).width() <= MIN_WIDTH && (currPos !== (topOffset - header))) {
                        $('html,body').animate({
                            scrollTop: topOffset - header
                        }, 300);
                    }
                });
            }
        };
    }])

    .directive('itemLiMenuToogle', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            replace: false,
            link: function (scope, element, attrs) {
                $timeout(function () {
                    element.on("click", function () {
                        $(this).parents("li").toggleClass("with-menu");
                        return false;
                    });
                }, 50);
            }
        };
    }])

    .directive('wpTheme', [function () {
        var LINK_REL = 'stylesheet',
            LINK_TYPE = 'text/css',
            LINK_ID = 'theme-link-stylesheet';
        return {
            restrict: 'A',
            controller: ['$scope', function ($scope) {
                $scope.wp_w_profile_d_theme_include = function (style) {
                    var head = document.head;
                    var prev_link = document.getElementById(LINK_ID);
                    if (prev_link) {
                        head.removeChild(prev_link);
                    }

                    var link = document.createElement('link');
                    link.id = LINK_ID;
                    link.type = LINK_TYPE;
                    link.rel = LINK_REL;
                    link.href = style;

                    head.appendChild(link);
                };
            }],
            link: function ($scope, element, attrs) {
                var TOGGLE_CLASS = 'selected';
                element.bind('click', function () {
                    $scope.wp_w_profile_d_theme_include(attrs.wpTheme);

                    $('.theme').removeClass(TOGGLE_CLASS);
                    element.addClass(TOGGLE_CLASS);
                });
            }
        };
    }])

    .directive('groupMenuToggle', [function () {
        return {
            restrict: 'C',
            link: function (scope, element, attrs) {
                element.ready(function () {
                    element.click(function () {
                        $(this).parents(".navigation, .widget-submenu").toggleClass("opened");
                        return false;
                    });
                });

                scope.$on('$routeChangeSuccess', function () {
                    $(".navigation, .widget-submenu").removeClass("opened");
                });
            }
        };
    }])

    .directive('submenuToggle', [function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                element.ready(function () {
                    element.click(function () {
                        $(this).parents(".navigation").toggleClass("opened");
                        return false;
                    });
                });
            }
        };
    }])

    .directive('ngMinAmount', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attr, ctrl) {
                scope.$watch(attr.ngMinAmount, function () {
                    ctrl.$setViewValue(ctrl.$viewValue);
                });

                function isEmpty(value) {
                    return angular.isUndefined(value) || value === '' || value === null || value !== value;
                }

                var minValidator = function (value) {
                    var min = scope.$eval(attr.ngMinAmount) || 0;
                    if (!isEmpty(value) && parseInt(value, 10) < parseInt(min, 10)) {
                        ctrl.$setValidity('ngMinAmount', false);
                        return undefined;
                    } else {
                        ctrl.$setValidity('ngMinAmount', true);
                        return value;
                    }
                };

                ctrl.$parsers.push(minValidator);
                ctrl.$formatters.push(minValidator);
            }
        };
    })

    .directive('ngMaxAmount', function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, elem, attr, ctrl) {
                scope.$watch(attr.ngMaxAmount, function () {
                    ctrl.$setViewValue(ctrl.$viewValue);
                });

                function isEmpty(value) {
                    return angular.isUndefined(value) || value === '' || value === null || value !== value;
                }

                var maxValidator = function (value) {
                    var max = scope.$eval(attr.ngMaxAmount) || 1000000;
                    if (!isEmpty(value) && parseInt(value, 10) > parseInt(max, 10)) {
                        ctrl.$setValidity('ngMaxAmount', false);
                        return undefined;
                    } else {
                        ctrl.$setValidity('ngMaxAmount', true);
                        return value;
                    }
                };

                ctrl.$parsers.push(maxValidator);
                ctrl.$formatters.push(maxValidator);
            }
        };
    })

    .directive('disableNgAnimate', ['$animate', function ($animate) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                $animate.enabled(false, element);
            }
        };
    }])

    .directive('formDirective', ['$http', '$q', function ($http, $q) {
        return {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                'condition': '='
            },
            link: function (scope, element, attrs, ngModel) {
                scope.$watch(function () {
                    return scope.condition.fEl_data['el_css_class'];
                }, function (condition) {
                    if (condition === 'agent-code') {
                        ngModel.$asyncValidators.form_author = function (modelValue, viewValue) {
                            var deferred = $q.defer();
                            if (viewValue !== null) {
                                $http.post('/request/users/users/agentCodeCheck', {agentCode: viewValue}).then(
                                    function (response) {
                                        if (!response.data.exist) {
                                            scope.condition.fEl_data['el_error_custom'] = 'Агента с таким кодом не существует!';
                                            ngModel.$setValidity('custom', false);
                                            deferred.resolve(false);
                                        } else {
                                            scope.condition.fEl_data['el_error_custom'] = '';
                                            ngModel.$setValidity('custom', true);
                                            deferred.resolve(true);
                                        }
                                    }
                                );
                            } else {
                                scope.condition.fEl_data['el_error_custom'] = '';
                                ngModel.$setValidity('custom', true);
                                deferred.resolve(true);
                            }

                            return deferred.promise;
                        };
                    }
                });
            }
        };
    }])

    .directive('wpResponsiveTab', ['$timeout', '$compile', '$window', function ($timeout, $compile, $window) {
        return {
            restrict: 'A',
            controller: ['$scope', '$element', function ($scope, $element) {
            }],
            link: function (scope, element, attr, ctrl) {
                if (attr['wpResponsiveTab'] !== undefined && attr['wpResponsiveTab'] === 'responsive') {
                    var $tab;
                    scope.wpResponsiveTabActive = [];
                    $timeout(function () {
                        //scope.wpResponsiveTabActive = [0, 1, 0, 0];
                        $tab = element.find('.nav.nav-tabs');
                        $tab.addClass('responsive');

                        element.find('.tab-content').addClass('responsive');
                        element.addClass('wp-responsive-tabs');

                        scope.groups = [];
                        element.find('.nav.nav-tabs a').each(function (ind, el) {
                            var _el = $(el).find('tab-heading');
                            if (_el['length'] > 0) {
                                el = _el[0];
                            }

                            scope.groups.push({
                                title: $(el).html(),
                                content: element.find('.tab-content .tab-pane').eq(ind).html()
                            });

                        });

                        var acc = $('<div class="responsive-accordion-wrapper"><uib-accordion><uib-accordion-group ng-repeat="group in groups" is-open="wpResponsiveTabActive[$index]"><uib-accordion-heading><span data-bind-compiled-html="group.title"></span></uib-accordion-heading><div data-bind-compiled-html="group.content"></div></uib-accordion-group></uib-accordion></div>');
                        element.append($compile(acc)(scope));
                    });

                    angular.element($window).on('orientationchange resize', function (e) {
                        if ($tab !== undefined && $tab.is(':visible') && !scope.wpResponsiveTabActive.some(function (o) {
                                return o;
                            })) {
                            scope.$apply(function () {
                                scope.wpResponsiveTabActive[0] = true;
                            });
                        }
                    });
                }
            }
        };
    }]);
