angular.module('wp-common-directives', ['wp-common-services', 'wp-common-filters'])

    .directive('wpSupportClasses', ['$window',
        function ($window) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    var html = angular.element('html'),
                        wind = angular.element($window),
                        checkSize = function () {
                            var width = wind.width();
                            html.toggleClass('desktop', width > 979);
                            html.toggleClass('desktop-large', width > 1200);
                            html.toggleClass('tablet', width > 767 && width < 978);
                            html.toggleClass('mobile', wind.width() < 768);

                            wind.trigger('resize.updatedSupportClasses');
                        };

                    ///check window width
                    checkSize();
                    wind.bind('resize orientationchange', function () {
                        checkSize();
                    });

                    ///check touch device
                    html.addClass('no-touch');
                    if ('ontouchstart' in $window || $window.navigator.msMaxTouchPoints) {
                        html.removeClass('no-touch').addClass('touch');
                    }
                }
            };
        }])


    .directive('wpwidgetimage', ['$compile', '$rootScope',
        function ($compile, $rootScope) {
            return {
                replace: true,
                scope: {
                    wpwidgetimage: "@"
                },
                link: function (scope, element, attrs) {
                    element.ready(function () {
                        scope.src = $rootScope.wpPublishDir + scope.wpwidgetimage;
                        element.replaceWith($compile("<img ng-src='{{src}}' alt=''/>")(scope));
                    });
                }
            };
        }])


    .directive('wpinclude', ["$rootScope", function ($rootScope) {
        return {
            restrict: 'A',
            replace: true,
            scope: true,
            template: '<div data-ng-include="template2"></div>',
            controller: ['$scope', '$element', '$attrs', '$transclude', function ($scope, $element, $attrs, $transclude) {
                /*$scope.$watch(function () {
                 return $attrs.template;
                 }, function (newValue, olva) {
                 $scope.template2 = $rootScope.wpWidgetsDir + newValue;
                 });*/
            }],
            link: function ($scope, element, attrs) {
                $scope.template2 = (attrs.wpinclude.length > 0 ? attrs.wpinclude + '/widget/' : $rootScope.wpWidgetsDir) + attrs.template;
            }
        };
    }])


    .directive('displayelement', ['$compile', '$http', '$templateCache', 'element',
        function ($compile, $http, $templateCache, elementObj) {

            var getTemplate = function (layoutType) {
                var templateLoader;
                var templateUrl = '/theme/templates/directives/element/' + elementObj.getElementLayout(layoutType);
                templateLoader = $http.get(templateUrl, {
                    cache: $templateCache
                });
                return templateLoader;
            };

            var linker = function (scope, element, attrs) {
                scope.elementObj = elementObj;
                var loader = getTemplate(attrs.elementtype);
                var promise = loader.success(function (html) {
                    element.html(html);
                }).then(function (response) {
                    element.replaceWith($compile(element.html())(scope));
                });
            };

            return {
                restrict: 'A',
                require: '?elementtype',
                scope: {
                    index: '@',
                    model: '='
                },
                link: linker
            };
        }])


    .directive('jqslide', ['$timeout', '$window',
        function ($timeout, $window) {

            return function (scope, elm, attrs) {
                var v = elm.find('video');
                v.css({display: 'none'});

                $timeout(function () {
                    var setting = {
                        controls: true,
                        useCSS: false,
                        touchEnabled: false,
                        onSlideAfter: function () {
                            v.css({display: 'block'});
                            v.trigger('play');
                        },
                        onSliderLoad: function () {
                            $(".special-offers-slider").css("visibility", "visible");
                            elm.find('>.bx-slide.slide video').trigger('play');
                            elm.parents('.wp-widget-banner').css({opacity: 1});
                        }
                    };
                    if (attrs.speed > 0) {
                        setting.auto = true;
                        setting.pause = attrs.speed * 1000;

                    }
                    if (elm.children().size() >= 1) {
                        var slider = elm.bxSlider(setting);
                        if (setting.pause > 0) {
                            $(".bx-controls-direction a").click(function () {
                                slider.startAuto();
                                return false;
                            });
                        }
                        angular.element($window).bind("orientationchange load resize", function () {
                            slider.reloadSlider();
                        });
                    }
                }, 100);
            };
        }])


    .directive('compileHtmlAfterModelLoad', ['$compile', '$http', '$templateCache', '$rootScope',
        function ($compile, $http, $templateCache, $rootScope) {
            var renderTemplate = function (template) {
                var baseUrl = $rootScope.wpPublishDir + template;
                var promise = $http.get(baseUrl, {
                    cache: $templateCache
                });
                return promise;
            };
            return {
                restrict: 'A',
                replace: true,
                scope: {
                    model: '=',
                    setting: '=',
                    template: "@"
                },
                link: function (scope, element, attrs) {
                    scope.model.$promise.then(function (result) {
                        renderTemplate(scope.template).success(function (html) {
                            element.html(html);
                        }).then(function (response) {
                            element.replaceWith($compile(element.html())(scope));
                        });
                    });
                }
            };

        }])


    .directive('angdatepicker', ['$timeout',
        function ($timeout) {
            return {
                restrict: 'A',
                replace: false,
                scope: {
                    setting: "=",
                    ngDisabled: '=',
                    ngModel: '='
                },
                link: function (scope, element, attrs) {
                    var setting = angular.fromJson(scope.setting);

                    setting.dateFormat = "dd.mm.yy";
                    setting.disabled = attrs.disabled ? true : false;
                    setting.showOn = "focus";

                    if (typeof setting.minDate == 'boolean') {
                        setting.minDate = new Date();
                    }

                    $timeout(function () {
                        element.datepicker(setting);
                        element.datepicker("hide");
                    }, 100);

                    scope.$watch(function () {
                        return scope.ngDisabled;
                    }, function (modelValue) {
                        var date = $.datepicker.formatDate(setting.dateFormat, element.datepicker("getDate"));
                        if (date) {
                            scope.ngModel = date;
                        }

                    });

                    attrs.$observe('disabled', function () {
                        element.datepicker("option", "disabled", attrs.disabled ? true : false);
                        $("#ui-datepicker-div").css('display', 'none');
                    });
                }
            };
        }])


    .directive('fullpager', ['$timeout', '$rootScope', function ($timeout, $rootScope) {
        var IN_PAGINATION = 4;
        return {
            restrict: 'A',
            replace: true,
            templateUrl: $rootScope.wpPublishDir + 'templates/directives/pager/pager.html',
            scope: {
                pagerModel: '='
            },
            controller: ['$scope', function ($scope) {
                var initPager = function () {
                    if (!_.isUndefined($scope.pagerModel)) {
                        $scope.total = Math.ceil($scope.pagerModel.total_object / $scope.pagerModel.onpage);
                        $scope.to = $scope.total < $scope.pagerModel.current_page + IN_PAGINATION ? $scope.total : $scope.pagerModel.current_page + IN_PAGINATION - 1;
                        if ($scope.total < IN_PAGINATION) {
                            $scope.from = 1;
                            $scope.prev = false;
                            $scope.next = false;
                        } else {
                            $scope.from = $scope.pagerModel.current_page + IN_PAGINATION <= $scope.total ? $scope.pagerModel.current_page : $scope.total - IN_PAGINATION + 1;
                            $scope.prev = $scope.pagerModel.current_page - 1;
                            $scope.next = $scope.pagerModel.current_page + IN_PAGINATION > $scope.total ? false : $scope.pagerModel.current_page + IN_PAGINATION;
                        }
                    }
                };

                $scope.initPager = initPager();
                $scope.setPage = function (n) {
                    $scope.pagerModel.current_page = n;
                };

                $scope.$watchCollection('[pagerModel.current_page,pagerModel.total_object]', initPager);
            }],
            link: function (scope) {
                $timeout(function () {
                    scope.$apply(function () {
                        scope.initPager();
                    });
                });
            }
        };
    }])


    .directive('wpPhone', [function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function ($scope, element, attrs, ctrl) {
                var mask;
                var json = Polygons.getAppAssetsDir('commonDir') + 'resource/phone-codes.json';
                var maskList = $.masksSort($.masksLoad(json), ['#'], /[0-9]|#/, "mask");
                var maskOpts = {
                    inputmask: {
                        definitions: {
                            '#': {
                                validator: "[0-9]",
                                cardinality: 1
                            }
                        },
                        clearIncomplete: true,
                        showMaskOnHover: false,
                        autoUnmask: true
                    },
                    match: /[0-9]/,
                    replace: '#',
                    list: maskList,
                    listKey: "mask",
                    onMaskChange: function (maskObj, completed) {
                        if (completed) {
                            var hint = maskObj.name_ru;
                            if (maskObj.desc_ru && maskObj.desc_ru != "") {
                                hint += " (" + maskObj.desc_ru + ")";
                            }
                            $('#phone-description').html(hint);
                        } else {
                            $('#phone-description').html('');
                        }
                        $(this).attr("placeholder", $(this).inputmask("getemptymask"));

                        mask = maskObj.mask.replace(/\+|\-|\(|\)/g, '');
                        if (element.val().length === mask.length) {
                            element.trigger('input');
                            ctrl.$setValidity('required', true);
                        }

                        if (!element.val()) {
                            ctrl.$setValidity('required', false);
                        }
                    }
                };

                element.inputmasks(maskOpts);
                /*element.inputmask("phone", {
                 url: json,
                 onKeyValidation: function (a, b, c, d) { //show some metadata in the console
                 var hint = $(this).inputmask("getmetadata") ? $(this).inputmask("getmetadata")["name_ru"] : '';
                 element.siblings('.help-block').html(hint);
                 }
                 });*/
                // element.change();
                element.on('keypress', function () {
                    ctrl.$setValidity('required', true);
                });

                element.on('blur', function () {
                    if (ctrl && element.val().length < mask.length) {
                        ctrl.$setViewValue(null);
                        ctrl.$render();
                    }
                });
            }
        };
    }])


    .directive('ngEnter', [function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                element.bind("keydown keypress", function (event) {
                    if (event.which === 13) {
                        scope.$apply(function () {
                            scope.$eval(attrs.ngEnter);
                        });

                        event.preventDefault();
                    }
                });
            }
        };
    }])


    .directive('wpSpinner', ['$timeout', '$http', function ($timeout, $http) {

        var options = {
            bg: true,
            lines: 15, // The number of lines to draw
            length: 2, // The length of each line
            width: 4, // The line thickness
            radius: 20, // The radius of the inner circle
            corners: 0.7, // Corner roundness (0..1)
            rotate: 0, // The rotation offset
            direction: 1, // 1: clockwise, -1: counterclockwise
            color: '#CF0800', // #rgb or #rrggbb or array of colors
            speed: 1, // Rounds per second
            trail: 56, // Afterglow percentage
            shadow: false, // Whether to render a shadow
            hwaccel: false, // Whether to use hardware acceleration
            className: 'spinner', // The CSS class to assign to the spinner
            zIndex: 2e9, // The z-index (defaults to 2000000000)
            position: 'fixed',
            top: '50%', // Top position relative to parent
            left: '50%' // Left position relative to parent
        };

        return {
            restrict: 'A',
            scope: {
                options: '@'
            },
            controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
                var promise = false;
                $scope.$watch(function () {
                    return $http.pendingRequests.length;
                }, function (newValue, oldValue) {
                    if (newValue > 0) {
                        if (promise)
                            $timeout.cancel(promise);
                        $scope.spinner.show();
                    } else {
                        promise = $timeout(function () {
                            $scope.spinner.hide();
                            promise = false;
                        }, 500);
                    }
                });
            }],
            link: function (scope, element, attrs) {
                scope.spinner = new Spinner(angular.extend({}, options, angular.fromJson(scope.options))).spin(document.getElementsByTagName('body')[0]);
            }
        };
    }])


    .directive('wpLoading', ['$timeout', '$animate', '$rootScope', '$q', 'WidgetsDefer', function ($timeout, $animate, $rootScope, $q, WidgetsDefer) {
        var defaultdelay = 300;
        return {
            restrict: 'A',
            scope: {
                timedelay: '@'
            },
            controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {

                $element.addClass('animate-show');


                $rootScope.$on('$viewContentLoaded', function (event, next, current) {
                    $animate.addClass($element, 'loading');
                });


                $rootScope.$on('$routeChangeStart', function (event, next, current) {
                    $animate.addClass($element, 'loading');
                });

                var removeClass = function () {
                    $timeout(function () {
                        $animate.removeClass($element, 'loading');
                        $animate.removeClass($element, 'animate-show');
                    }, $scope.timedelay || defaultdelay);
                };

                $scope.$on('wp_common_directives_loading_remove_class', function (event, next, current) {
                    removeClass();
                });

                $scope.$watch(function () {
                    return Object.keys(WidgetsDefer.getPromises()).length > 0;
                }, function (newValue, oldValue) {
                    if (newValue) {
                        $q.all([WidgetsDefer.getPromises()]).then(function () {
                            WidgetsDefer.resetDefers();
                            removeClass();
                        });
                    } else {
                        removeClass();
                    }
                });
            }]
        };
    }])


    .directive('wpWidget', ['$timeout', 'WidgetsDefer',
        function ($timeout, WidgetsDefer) {
            return {
                restrict: 'A',
                scope: false,
                controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {

                    $element.css({opacity: 1});

                    $timeout(function () {
                        WidgetsDefer.resolveDefer($attrs.wpWidget);
                    });
                }]
            };
        }])


    .directive('wpAdaptive', [
        function () {
            return {
                restrict: 'A',
                scope: {
                    wpAdaptive: '='
                },
                controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
                    $scope.setViewport = function (adaptive) {
                        var viewport = '', ms = '';
                        if (adaptive === 1) {
                            viewport = "width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no";
                            ms = "@-ms-viewport{width:device-width;}";
                        } else {
                            viewport = "width=1024, initial-scale=1.1, user-scalable=yes";
                            ms = "@-ms-viewport{width:1024px}";
                        }
                        document.getElementById("viewport").setAttribute("content", viewport);
                        if (navigator.userAgent.match(/IEMobile/)) {
                            var msViewportStyle = document.getElementById("hackIeMobile");
                            if (msViewportStyle.childNodes.length > 0) {
                                msViewportStyle.childNodes[0].nodeValue = ms;
                            } else {
                                msViewportStyle.appendChild(document.createTextNode(ms));
                            }
                        }
                    };
                    $scope.setViewport($scope.wpAdaptive);

                    $scope.$watch(function () {
                        return $scope.wpAdaptive;
                    }, function (newValue, oldValue) {
                        if (newValue != oldValue) {
                            $scope.setViewport(newValue);
                        }
                    });
                }]
            };
        }])


    .directive('wpWindowResize', ['$window', function ($window) {
        return function (scope, element) {
            var w = angular.element($window);
            scope.getWindowDimensions = function () {
                return {
                    'h': w.height(),
                    'w': w.width()
                };
            };
            scope.$watch(scope.getWindowDimensions, function (newValue, oldValue) {
                scope.windowHeight = newValue.h;
                scope.windowWidth = newValue.w;

                scope.style = function () {
                    return {
                        'height': (newValue.h - 100) + 'px',
                        'width': (newValue.w - 100) + 'px'
                    };
                };

                scope.children = function () {
                    var ch = element.children();
                    return {
                        height: ch.height(),
                        width: ch.width()
                    }
                };

            }, true);

            w.bind('resize orientationchange', function () {
                scope.$apply();
            });
        };
    }])


    .directive('background', [function () {
        return {
            restrict: 'A',
            link: function (scope, element, attr) {
                var background = attr.background;
                if (background != 'false') {
                    element.css({'background-image': 'url("' + background + '")'});
                }
                element.addClass('photo');
            }
        };
    }])


    .directive('onlyDigits', [function () {
        return {
            restrict: 'A',
            require: '?ngModel',
            scope: {replaceWith: '='},
            link: function (scope, element, attrs, ngModel) {
                if (!ngModel) {
                    return;
                }

                function isEmpty(value) {
                    return angular.isUndefined(value) || value === '' || value === null || value !== value;
                }

                var replaceWith = scope.replaceWith ? scope.replaceWith : '';

                ngModel.$parsers.unshift(function (inputValue) {
                    var digits = (inputValue + "").trim().replace(/[^\d\-\+]/g, "");
                    digits = !isEmpty(digits) ? digits : replaceWith;
//                    ngModel.$setViewValue(digits);
                    ngModel.$viewValue = digits;
                    ngModel.$render();

                    return digits;
                });

                element.on('click', function () {
                    element.focus();
                    element.select();
                });
            }
        };
    }])


    .directive('topScroll', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            scope: {
                watchList: '=',
                scrollerId: '@'
            },
            link: function (scope, element, attrs, ngModel) {

                var _scroll = $('#' + scope.scrollerId);
                var element = $(element);
                scope.$watch('watchList', function () {
                    $timeout(function () {
                        $(_scroll.children()[0]).width(element.find('table')[0].scrollWidth);
                    }, 100);
                    hideScroll();
                }, true);

                _scroll.on('scroll', function () {
                    element.scrollLeft(_scroll.scrollLeft());
                });

                element.on('scroll', function () {
                    _scroll.scrollLeft(element.scrollLeft());
                });

                function offsetPosition(e) { // отступ от верхнего края экрана до элемента
                    var offsetTop = 0;
                    while (e = e.offsetParent) {
                        offsetTop += e.offsetTop;
                    }
                    ;
                    return offsetTop;
                }
                ;

                $(window).on('scroll', function () {
                    // если значение прокрутки больше отступа от верхнего края экрана до элемента, то элементу присваивается класс stickyScroll
                    if (offsetPosition(_scroll[0]) < window.pageYOffset) {
                        _scroll.addClass('stickyScroll');
                        _scroll.css('width', _scroll.parent().width());
                    } else {
                        _scroll.removeClass('stickyScroll');
                        _scroll.css('width', "auto");
                    }
                });

                var content = $(element).children().get(0);
                var hideScroll = function () {
                    $timeout(function () {
                        var contntent_width = $(content).width();
                        var scroll_width = _scroll.width();
                        if (contntent_width <= scroll_width) {
                            _scroll.hide();
                        } else {
                            _scroll.show();
                        }
                    }, 200);
                };
                $(window).on('resize', function () {
                    if (offsetPosition(_scroll[0]) < window.pageYOffset) {
                        _scroll.css('width', _scroll.parent().width());
                    }
                    hideScroll();
                });
            }
        };
    }])


    .directive('dotdotdot', ['$timeout', function ($timeout) {
        return {
            restrict: 'A',
            replace: false,
            scope: {
                heigth: '='
            },
            link: function (scope, element, attrs) {
                $timeout(function () {
                    $(element).dotdotdot();
                });
            }
        };
    }])


;
